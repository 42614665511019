// @flow
import * as React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { effects } from '../../../styles/theme'

import type { MainMenuItem } from '../../../types/MainMenuItem'
import type { MenuItemCategory } from '../../../types/MenuItemCategory'
import type { LinkViewModel } from '../../../types/LinkViewModel'

import AccordionContainer from '../../../components/AccordionElements/AccordionContainer'
import AccordionItem from '../../../components/AccordionElements/AccordionItem'
import BaseButton from '../../../components/Button/BaseButton'
import Link from '../../../components/Link'
import ZoomIcon from '../../../icons/ZoomIcon'
import LogoutIcon from '../../../icons/LogoutIcon'
import { mediaQuery } from '../../../styles/media'
import consecutive from 'consecutive'

const MENU_ITEM_ICONS = {
  search: ZoomIcon,
  exit: LogoutIcon,
}

type Props = {
  children?: React.Node,
  isOpen?: boolean,
  onClose?: Function,
  menu?: Array<MainMenuItem>,
  searchLink?: LinkViewModel,
  alternateLink?: LinkViewModel,
  secondAlternate?: LinkViewModel,
  serviceMenu?: Array<LinkViewModel>,
}

const OverlayWrapper = styled.div`
  background-color: white;
  padding: ${rem(24)} 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;

  position: absolute;
  top: 72px;
  right: 0;
  left: 0;
  bottom: 0;
  height: calc(
    100vh - 72px
  ); /* todo: needs to take into account header height */

  z-index: 1;
  transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: ${({ isOpen }) =>
    !isOpen ? 'translateY(-100vh)' : 'translateY(0)'};

  ${mediaQuery.sm`
    padding-left: 70px;
    padding-right: 70px;
  `}

  ${mediaQuery.md`
    padding-left: 100px;
    padding-right: 100px;
  `}
`

const MenuItem = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-self: center;

  font-weight: bold;
  padding: ${rem(30)} ${rem(32)};

  border-top: 1px solid #e3e3e3;

  :first-of-type {
    border-top: none;
  }

  :last-of-type {
    border-bottom: 1px solid #e3e3e3;
  }
`

const SubLink = styled(BaseButton)`
  width: 100%;
  font-weight: bold;
  background-color: ${({ theme }) => theme.accordionBodyStyle};
  padding: ${rem(24)} 0;
  border-bottom: ${({ theme }) => effects(theme).borderPositive};

  :last-of-type {
    border-bottom: none;
  }
`
// inline styles
const accordionTitleContainerStyle = {
  paddingLeft: '30px',
  paddingRight: '30px',
}

const accordionTitleStyle = {
  fontSize: '1rem',
  fontWeight: 'bold',
}

const accordionBodyStyle = {
  paddingLeft: '40px',
  paddingRight: '40px',
}

const accordionItemIconStyle = {
  marginRight: 0,
}

function HeaderDeviceOverlay({
  isOpen,
  menu,
  searchLink,
  alternateLink,
  secondAlternate,
}: Props) {
  const renderMenuItem = (item: MainMenuItem, key: number) => {
    const keyGen = consecutive()

    if (
      !item.mainLink ||
      !item.mainLink.label ||
      (!(item.column1 || []).length &&
        !(item.column2 || []).length &&
        !(item.column3 || []).length)
    )
      return null // Do not display if no title or no links

    return (
      <AccordionItem
        isMenu
        id={String(key)}
        key={key}
        prefix="header-device-overlay"
        // $FlowFixMe
        title={item.mainLink.label}
        titleStyle={accordionTitleContainerStyle}
        titleInnerStyle={accordionTitleStyle}
        itemBodyStyle={{
          ...accordionBodyStyle,
        }}
        iconStyle={accordionItemIconStyle}
      >
        {item.column1 && item.column1.map(renderSubLink)}
        {item.column2 && item.column2.map(renderSubLink)}
        {item.column3 && item.column3.map(renderSubLink)}
        {item.column4 && item.column4.map(renderSubLink)}
      </AccordionItem>
    )
  }

  const renderMenuItemAsLink = (
    link?: LinkViewModel,
    key: number = 0,
    iconType?: string,
  ) => {
    if (!link || !link.href) return
    const Icon = iconType && MENU_ITEM_ICONS[iconType]

    return (
      <MenuItem key={link.label} {...link}>
        {link.label}
        {Icon && <Icon />}
      </MenuItem>
    )
  }

  const renderSubLink = (item: MenuItemCategory) => {
    if (!item || !item.title || !item.title.label) return null // Do not display if no title

    return (
      <SubLink key={item.title.label} {...item.title}>
        {item.title.label}
      </SubLink>
    )
  }

  return (
    <OverlayWrapper isOpen={isOpen}>
      <AccordionContainer>
        {/* $FlowFixMe */}
        {menu &&
          menu.length > 0 &&
          menu.map((item, key) =>
            item.mainLink && item.mainLink.href
              ? renderMenuItemAsLink(item.mainLink, key)
              : renderMenuItem(item, key),
          )}
        {searchLink &&
          searchLink.href &&
          renderMenuItemAsLink(searchLink, 0, 'search')}
        {alternateLink &&
          alternateLink.href &&
          renderMenuItemAsLink(alternateLink, 0, 'exit')}
        {secondAlternate &&
          secondAlternate.href &&
          renderMenuItemAsLink(secondAlternate, 0, 'exit')}
      </AccordionContainer>
    </OverlayWrapper>
  )
}

HeaderDeviceOverlay.displayName = 'HeaderDeviceOverlay'
HeaderDeviceOverlay.defaultProps = {}

export default HeaderDeviceOverlay
