// @flow
import React from 'react'
import styled, { ThemeContext } from 'styled-components'
import { rem } from 'polished'

import { color } from '../../../styles/theme'

import type { LinkViewModel } from '../../../types/LinkViewModel'
import type { MainMenuItem } from '../../../types/MainMenuItem'

import Logo from '../../../components/Logo/Logo'
import LogoutIcon from '../../../icons/LogoutIcon'
import Ribbon from '../../../components/Ribbon/Ribbon'

import MenuItem, { HeaderLink } from './HeaderDesktopMenuItem'
import { Link } from '../../../components/Link/Link'
import SearchIcon from '../../../icons/SearchIcon'

type Props = {
  siteName?: string,
  home?: LinkViewModel,
  mainMenu?: Array<MainMenuItem>,
  search?: LinkViewModel,
  alternate?: LinkViewModel,
  secondAlternate?: LinkViewModel,
  activeMenuKey?: number,
  isOpen?: boolean,
  onHover: Function,
  onClose: Function,
  closeLabel?: string,
}

function HeaderDesktop(props: Props) {
  const {
    siteName,
    home,
    mainMenu,
    search,
    alternate,
    secondAlternate,
    onClose,
  } = props
  const theme = React.useContext(ThemeContext)

  return (
    <HeaderDesktopWrapper aria-label="main menu">
      <Ribbon marginBottom={false}>
        <MainRow middle>
          {home && (
            <LogoWrapper>
              <Logo name={siteName} link={home} onFocus={onClose} />
            </LogoWrapper>
          )}
          <Menu>
            {mainMenu && mainMenu.length > 0
              ? mainMenu.map((item, key) => (
                  <MenuItem
                    {...props}
                    itemKey={key}
                    menuItem={item}
                    key={key}
                  />
                ))
              : null}
            <SearchWrapper>
              {!!search && !!search.href && (
                <HeaderLink {...search} right={0}>
                  <SearchIcon aria-hidden="true" style={{ marginRight: 8 }} />
                  {search && search.label}
                </HeaderLink>
              )}
            </SearchWrapper>
          </Menu>
        </MainRow>
        <StaticItems>
          {!!alternate && !!alternate.href && theme.name !== 'lgbt' ? (
            <AlternateLinkStyle {...alternate} right={0} target="_blank">
              <AlternateWrapper>
                {alternate && alternate.label}
                <AlternateIcon />
              </AlternateWrapper>
            </AlternateLinkStyle>
          ) : null}
          {!!secondAlternate && !!secondAlternate.href ? (
            <AlternateLinkStyle {...secondAlternate} right={0} target="_blank">
              <AlternateWrapper>
                {secondAlternate && secondAlternate.label}
                <AlternateIcon />
              </AlternateWrapper>
            </AlternateLinkStyle>
          ) : null}
        </StaticItems>
      </Ribbon>
    </HeaderDesktopWrapper>
  )
}

export default HeaderDesktop

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`

const HeaderDesktopWrapper = styled.nav`
  background-color: ${color.white};
`

const Menu = styled.div`
  display: flex;
  flex: 0.3 1 auto;
  justify-content: space-between;
  flex-wrap: nowrap;
`

const StaticItems = styled.div`
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  padding-bottom: 1rem;
`

const MainRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  height: ${rem(80)};
`

const LogoWrapper = styled.div`
  height: ${rem(32)};
  display: flex;
  /* Alignment that is specific logo svg */
  margin-top: ${rem(21)};
  margin-right: auto;
`
const AlternateWrapper = styled.div`
  display: flex;
`
const AlternateLinkStyle = styled(Link)`
  border-radius: 62px;
  padding: 8px 16px;
  font-size: 16px;
  background: #c4e1e4;
`

const AlternateIcon = styled(LogoutIcon)`
  width: 14px;
  height: 14px;
  margin-left: ${rem(8)};
`
